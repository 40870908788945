import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ImageThree from '../components/images/image-three'

import step1 from '../images/Spain-Step1.png'
import step2 from '../images/Spain-Step2.png'
import step3 from '../images/Spain-Step3.png'
import step4 from '../images/Spain-Step4.png'

const StepsImages = styled.ul`
  li {
    display: inline-block;
    overflow: hidden;
    width: 25%;
    text-align: center;
    @media ( max-width: 900px) {
      width: 100%;
    }
  }
`

const QueEsPage = () => (
  <Layout>
    <SEO title="Que es el Metodo Mckenzie " />
    <p>El Método McKenzie® de Diagnóstico y Terapia Mecánica® (MDT) es un método reconocido internacionalmente de evaluación y tratamiento para la columna y las extremidades desarrollado en Nueva Zelanda por el fisioterapeuta Robin McKenzie. Ha sido utilizado en todo el mundo durante más de 30 años.</p>
    <p>Los fisioterapeutas MDT están formados para evaluar y diagnosticar cualquier problema a nivel músculo-esquelético. Si existe un problema  en la columna, articulación o músculo la evaluación MDT va a ser apropiada.</p>
    <ImageThree />
    <p>MDT es una filosofía que involucra y educa al paciente de una forma activa que se utiliza por fisioterapeutas y pacientes de todo el mundo para patologías de espalda, cuello y extremidades. Este abordaje sigue siendo uno de los métodos que dispone de la mayor evidencia científica.</p>
    <p>Uno de los puntos clave es la evaluación inicial pues es una manera segura y fiable de llegar a un diagnóstico preciso lo que nos permite diseñar un plan de tratamiento apropiado. Muchas veces, las pruebas diagnósticas caras(resonancias,etc) no son necesarias. Fisioterapeutas acreditados en MDT son capaces de determinar de una manera rápida si el método será efectivo para el paciente o, por el contrario, deben ser derivados a otro tipo de tratamientos.</p>
    <p>MDT es un abordaje basado en unos principios y procedimientos que una vez se conocen y se llevan a cabo son muy efectivos.</p>
    <StepsImages>
      <li><img src={step1} alt={`mdt-step-one`} /></li>
      <li><img src={step2} alt={`mdt-step-two`} /></li>
      <li><img src={step3} alt={`mdt-step-three`} /></li>
      <li><img src={step4} alt={`mdt-step-four`} /></li>
    </StepsImages>
        <Link to="/como-funciona/" className={`mdt-button-simple`}>Como Funciona el Metodo McKenzie</Link>
  </Layout>
)

export default QueEsPage
